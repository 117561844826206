import { useLocation } from '@reach/router'
import React, { ChangeEvent, useState } from 'react'

import { useForm } from 'react-hook-form'

import { sendCDPFormData } from 'src/shared/cdp'

import Message from './Message'

import { DataLayerProps, useDataLayer } from 'src/hooks/useDataLayer'
import { sendNewsletterFrom } from 'src/shared/helpers'
import { Button } from 'src/styles/button'
import { Post } from 'src/types/Post'
import { Theme } from 'src/types/Theme'
import { Form } from './style'

type NewsletterProps = {
  pageContext?: Post;
  name: string;
  theme?: Theme;
  category?: string;
  subCategory?: string;
}

const Newsletter = ({ name, category, subCategory, theme = 'pf', pageContext }: NewsletterProps) => {
  const { register, errors, handleSubmit }: Pick<ReturnType<typeof useForm>, 'register' | 'errors' | 'handleSubmit' | 'setValue'> = useForm()
  const [ loading, setLoading ] = useState(false)
  const [ acceptedTerms, setAcceptedTerms ] = useState(false)
  const [ status, setStatus ] = useState('')
  const { sendDataLayer }: { sendDataLayer: (data: DataLayerProps) => void } = useDataLayer()
  const location = useLocation()

  function handleCheck (event: ChangeEvent<HTMLInputElement>) {
    const inputValue = event.currentTarget.checked
    setAcceptedTerms(inputValue)
  }

  const sendForm = async (data: Record<string, string>) => {
    setLoading(true)
    const success = await sendNewsletterFrom(data.nomeCompleto, data.email)
    if (success) {
      setStatus('success')
      sendCDPFormData({
        formName: 'newsletter-blog',
        cpf: '',
        email: data.email,
        categoria: category,
        subCategoria: subCategory,
        tituloPagina: document.title,
      })
    } else {
      setStatus('error')
    }
    setLoading(false)

    sendDataLayer({
      event: 'ga_event_body_blog',
      params: {
        section: pageContext?.title || 'null',
        sub_section: 'Assine nossa newsletter',
        element_action: 'submit',
        element_name: 'Assine nossa newsletter',
        c_page: location.href,
        redirect_url: 'null',
        segment: category || 'null',
        sub_segment: subCategory || 'null',
      },
    })
  }

  return (
    status !== ''
      ? <Message status={status} setStatus={setStatus} />
      : (
        <Form onSubmit={handleSubmit(sendForm)} theme={theme}>
          <div className='mb-3'>
            <label htmlFor='nomeCompleto' className='fs-14 fw-600 w-100 mb-1'>Nome</label>
            <input
              ref={register({
                required: 'O nome é obrigatório',
              })}
              name='nomeCompleto'
              id='nomeCompleto'
              type='text'
              placeholder='Digite seu nome completo'
            />
            { errors.nomeCompleto && <p className='fs-12 text-red-base my-2'>{errors.nomeCompleto.message}</p> }
          </div>
          <div className='mb-1'>
            <label htmlFor='email' className='fs-14 fw-600 w-100 mb-1'>E-mail</label>
            <input
              ref={register({
                required: 'E-mail obrigatório',
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: 'E-mail inválido',
                },
              })}
              name='email'
              id='email'
              type='text'
              placeholder='Informe seu e-mail'
            />
            { errors.email && <p className='fs-12 text-red-base my-2'>{errors.email.message}</p> }
          </div>
          <div className='checkbox mb-3'>
            <input
              ref={register()}
              id={name}
              name={name}
              type='checkbox'
              onChange={(event: ChangeEvent<HTMLInputElement>) => handleCheck(event)}
            />
            <label className='formLabelCheck fs-12 lh-16 text-grayscale-400' htmlFor={name}>
              Autorizo o envio de conteúdo sobre investimentos, educação financeira e novos produtos financeiros e estou de acordo com a
              <a
                className='fw-700' title='Acessar Política de Privacidade do Banco Inter'
                href='https://inter.co/politica-de-privacidade/'
                target='_blank' rel='noreferrer'
                onClick={() => {
                  sendDataLayer({
                    event: 'ga_event_body_blog',
                    params: {
                      section: 'dobra_06',
                      sub_section: 'Assine nossa newsletter',
                      element_action: 'click button',
                      element_name: 'Política de Privacidade',
                      c_page: location.href,
                      redirect_url: 'https://inter.co/politica-de-privacidade/',
                      segment: 'null',
                      sub_segment: 'null',
                    },
                  })
                }}
              > Política de Privacidade
              </a> do Inter.
            </label>
          </div>
          <div className='w-100'>
            <Button
              type='submit'
              title='Receber novidades'
              disabled={!acceptedTerms || loading}
              className='w-100'
            >
              {loading ? 'Enviando...' : 'Receber novidades'}
            </Button>
          </div>
        </Form>
      )
  )
}

Newsletter.defaultProps = {
  name: 'aceite',
}

export default Newsletter
