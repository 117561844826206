import React from 'react'
import { Helmet } from 'react-helmet'

export default function Accessibility () {
  return (
    <Helmet>
      <script
        type='text/javascript'
        src='https://cdn.rybena.com.br/dom/master/latest/rybena.js?backgroundColorBar=#FF7A00'
      />
    </Helmet>
  )
}
