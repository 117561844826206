import React from 'react'
import image404 from '../../assets/images/404.png'

import SEO from 'src/components/Seo'
import Layout from '../../components/Layout'

const NotFoundPage = () => (
  <Layout>
    <SEO
      title='Página não encontrada · Blog do Inter'
      url={`${process.env.BASE_URL}/404`}
      meta={[
        {
          property: 'robots',
          content: 'noindex',
        },
      ]}
    />
    <section className='pt-4 py-lg-5 text-center text-lg-left'>
      <div className='container pt-xl-5'>
        <div className='row'>
          <div className='col-12'>
            <h3 className='fs-30 fs-lg-42 text-grayscale--500 mb-md-5'>Ops... página não encontrada!</h3>
          </div>
          <div className='col-sm-12 col-lg-5 col-xl-6 order-lg-2 pb-4 pb-lg-0 text-center mt-lg-n4 mt-xl-n5'>
            <img className='img-fluid' src={image404} alt='404 image' />
          </div>
          <div className='col-sm-12 col-lg-7 col-xl-6 order-lg-1 pb-5'>
            <p className='fs-18 lh-22 fs-lg-20 text-grayscale--500'>
              Aqui estão alguns links que você pode estar procurando:
            </p>
            <ul className='list-unstyled link-list--404 fw-400'>
              <li>
                <a href='/' title='Home'>Home</a>
              </li>
              <li>
                <a
                  href='https://inter.co/pra-voce/conta-digital/pessoa-fisica/'
                  title='Conta Digital'
                >Conta Digital
                </a>
              </li>
              <li>
                <a href='https://inter.co/emprestimos/' title='Empréstimos'>Empréstimos</a>
              </li>
              <li>
                <a
                  href='https://inter.co/pra-voce/financiamento-imobiliario/residencial/'
                  title='Financiamento Imobiliário'
                >Financiamento Imobiliário
                </a>
              </li>
              <li>
                <a
                  href='https://inter.co/pra-voce/investimentos/'
                  title='Investimentos'
                >Investimentos
                </a>
              </li>
              <li>
                <a href='https://inter.co/pra-voce/seguros/' title='Seguro'>Seguro</a>
              </li>
              <li>
                <a href='https://inter.co/cambio/' title='Câmbio'>Câmbio</a>
              </li>
              <li>
                <a href='https://inter.co/consorcio/' title='Consórcio'>Consórcio</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default NotFoundPage
